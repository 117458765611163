<script>
    import { mapState } from "vuex";
    import BaseButton from "@frontend/base-button";
    import BaseInput from "@frontend/base-input";
    import BaseInputPhone from "@frontend/base-input-phone";

    export default {
        name: "TheFormWrapper",
        components: { BaseInputPhone, BaseInput, BaseButton },
        props: {
            title: String,
            nameButton: String,
            isLoading: Boolean,
            resultingErrorsList: Object,
            excludeInput: {
                default: () => [],
                type: Array,
            },
            successText: String,
            isHtmlResponse: Boolean,
            customNameField: {
                default: "Имя",
                type: String,
            },
        },
        data() {
            return {
                name: "",
                phone: "",
                email: "",
                errorsList: {
                    name: "",
                    phone: "",
                    email: "",
                },
            };
        },
        computed: {
            ...mapState("Profile", ["user"]),
        },
        watch: {
            resultingErrorsList: {
                handler(updatedList) {
                    this.errorsList = updatedList;
                },
                deep: true,
            },
        },
        mounted() {
            let firstField = this.$refs.formWrapper.children[0];
            if (firstField) firstField.focus();
            if (this.user?.id) {
                this.userDataPreload();
            }
        },
        methods: {
            send(e) {
                this.resetErrors();
                e.preventDefault();
                let dataFrom = {
                    name: this.name,
                    phone: this.phone,
                    email: this.email,
                };
                this.$emit("submit-form", dataFrom);
            },
            errorHandler(name) {
                this.errorsList[name] = null;
            },
            currentUsedInput(name) {
                return !this.excludeInput.includes(name);
            },
            resetErrors() {
                Object.keys(this.errorsList).forEach((key) => {
                    this.errorsList[key] = null;
                });
            },
            userDataPreload() {
                this.name = this.user.lastName
                    ? `${this.user.name} ${this.user.lastName}`
                    : this.user.name;
                this.phone = "7" + this.user.phone;
                this.email = this.user.email;
            },
        },
    };
</script>

<template>
    <div class="form-wrapper-model">
        <div v-if="successText" class="form-wrapper-model__success" v-html="successText"></div>
        <div v-else class="form-wrapper-model__sending">
            <div v-if="title" class="form-wrapper-model__title">
                {{ title }}
            </div>

            <form class="form-wrapper-model__form" action @submit="send">
                <div class="form-wrapper-model__form-wrapper" ref="formWrapper">
                    <base-input
                        v-if="currentUsedInput('name')"
                        v-model="name"
                        type="text"
                        name="name"
                        :placeholder="customNameField"
                        :error-text="errorsList.name"
                        @error="errorHandler"
                    ></base-input>

                    <base-input
                        v-if="currentUsedInput('email')"
                        v-model="email"
                        type="email"
                        name="email"
                        placeholder="Электронная почта"
                        :error-text="errorsList.email"
                        @error="errorHandler"
                    ></base-input>

                    <base-input-phone
                        v-if="currentUsedInput('phone')"
                        :value="phone"
                        :error-text="errorsList.phone"
                        @input="(e) => (this.phone = e)"
                        @error="errorHandler"
                    ></base-input-phone>
                    <slot></slot>
                </div>

                <div class="form-wrapper-model__controls">
                    <base-button
                        class="form-wrapper-model__button"
                        :variant="$root.$_variant_modal_button || 'v1'"
                        size="lg"
                        squared
                        :is-loading="isLoading"
                    >
                        {{ nameButton }}
                    </base-button>
                </div>
            </form>

            <div class="form-wrapper-model__personal-data">
                Нажимая на кнопку, вы даете согласие на
                <a :href="$root.$_documents.personalData" target="_blank">
                    обработку своих персональных данных
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .form-wrapper-model {
    }
    .form-wrapper-model__title {
        font-size: var(--modal-subheading-size);
        font-weight: var(--modal-subheding-weight);
        color: var(--modal-subheding-color);
        line-height: 24px;
        margin-bottom: 8px;
    }
    .form-wrapper-model__form-wrapper {
        margin-bottom: 16px;
    }
    .form-wrapper-model__controls {
        display: flex;
        justify-content: center;

        //button {
        //    font-size: var(--modal-button-text-size);
        //    font-weight: var(--modal-button-text-weight);
        //    border-radius: var(--modal-button-radius);
        //    background-color: var(--modal-button-fill);
        //    border-color: var(--modal-button-fill);
        //}
    }
    .form-wrapper-model__button {
        width: 100%;
    }
    .form-wrapper-model__personal-data {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px;

        a {
            color: var(--info-text--dark);
        }
    }

    .form-wrapper-model__success {
        font-size: 16px;
        line-height: 24px;
    }
</style>
