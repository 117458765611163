<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheModalWrap from "@main/packages/TheModalWrap";
    import TheFormWrapper from "@main/packages/TheFormWrapper";

    export default {
        name: "ModalOneClick",
        components: {
            TheFormWrapper,
            TheModalWrap,
        },
        props: {
            offerId: Number,
            productName: String,
            productPrice: Number,
            productSection: String,
            city: String,
        },
        data() {
            return {
                errorsList: {
                    name: "",
                    phone: "",
                },
                loading: false,
                successText: "",
            };
        },
        methods: {
            submit({ name, email, phone }) {
                const data = {
                    fullName: name,
                    offerId: this.offerId,
                    email,
                    phone,
                    city: this.city,
                };
                this.loading = true;
                this.$store.productsApi.sendOneClickForm(data).then((response) => {
                    this.loading = false;

                    if (response.success) {
                        this.responseSuccess(response);
                    } else {
                        this.responseError(response);
                    }
                });
            },
            responseSuccess(response) {
                this.successText = this.getSuccessTemplate(response.orderId);
                sendAnalytics.send(sendAnalytics.events.buyOneSuccess);

                if (this.$root.$_name === "picooc") {
                    try {
                        window.fbq("track", "Lead", {
                            content_name: this.productName,
                            content_category: this.productSection,
                            value: this.productPrice,
                            currency: "RUB",
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            responseError(response) {
                const responseErrors = Object.keys(response.errors);

                if (responseErrors.length > 0) {
                    responseErrors.forEach((error) => {
                        this.errorsList[error] = response.errors[error];
                        if (!response.errors.name && response.errors.secondName) {
                            this.errorsList.name = "Введите фамилию";
                        }
                    });
                }
            },
            getSuccessTemplate(orderId) {
                return `
    		<div class="one-click-modal__response">
    			<div class="one-click-modal__response-order">${orderId}</div>
    			<div>Номер вашего заказа</div>
    		</div>
    		<b>Ваш заказ принят!</b> Операторы свяжутся с вами в течение 30 минут (в рабочее время, будни: 9–20, выходные: 10—19)
    	`;
            },
        },
    };
</script>

<template>
    <the-modal-wrap title="Купить в один клик">
        <div class="one-click-modal">
            <the-form-wrapper
                name-button="Подтверждаю"
                :is-loading="loading"
                :resulting-errors-list="errorsList"
                @submit-form="submit"
                :success-text="successText"
                is-html-response
                custom-name-field="Имя и Фамилия"
            >
            </the-form-wrapper>
        </div>
    </the-modal-wrap>
</template>

<style lang="scss">
    .one-click-modal__response {
        margin-bottom: 15px;
        padding: 15px 0;
        text-align: center;
        background-color: #f9fafb;
    }
    .one-click-modal__response-order {
        margin-bottom: 10px;
        font-size: 28px;
        font-weight: bold;
        line-height: 32px;
    }
</style>
